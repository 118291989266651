export const environment = {
  pro: true,
  environmentName: 'QA',
  jwtValidation: false,
  urlServ: 'https://bca-ws-qa.gnp.com.mx/',
  urlServApi: 'https://api-qa.oscp.gnp.com.mx/',
  urlServApiGee: 'https://apigee-qa.oscp.gnp.com.mx/',
  clientIdOAuth: 'z3hMe2D6DDJhd2LLnibeMQz5G071G6BX5UNxOEO9vO5X6jXs',
  clientSecretOAuth: 'pxZVPTq0Iro0jugvsI3z62qGgpfGtAjZAHhFaRHqfDglVf9ikjSKq9AHsgSEMAM6',
};
